import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import actions from 'api/actions';

import { MembershipStatus, MembershipType } from 'api/types/membershipTypes';
import { getMembershipType, getMembershipStatus } from 'utils/account';
import { isSitterOwnerListingFavourite } from 'api/selectors/sitter';
import { isOwnerSitterProfileFavourite } from 'api/selectors/owner';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import Favourite from 'components/ListingFavourite';

class FavouriteComponent extends Component {
    constructor(props) {
        super(props);

        this.onFavourite = this.onFavourite.bind(this);
    }

    onFavourite() {
        const {
            listingId,
            profileId,
            isFavourited,
            isSitterOrCombined,
            createFavouriteAsSitter,
            createFavouriteAsOwner,
            removeFavouriteAsSitter,
            removeFavouriteAsOwner,
            afterFavouriteCallback,
            afterUnfavouriteCallback,
        } = this.props;

        let data;
        if (isSitterOrCombined) {
            data = {
                id: listingId,
            };
            if (isFavourited) {
                removeFavouriteAsSitter({
                    forceReload: true,
                    data,
                });
            } else {
                createFavouriteAsSitter({
                    forceReload: true,
                    data,
                });
            }
        } else {
            data = {
                id: profileId,
            };
            if (isFavourited) {
                removeFavouriteAsOwner({
                    forceReload: true,
                    data,
                });
            } else {
                createFavouriteAsOwner({
                    forceReload: true,
                    data,
                });
            }
        }
        if (afterFavouriteCallback && !isFavourited) {
            afterFavouriteCallback();
        }
        if (afterUnfavouriteCallback && isFavourited) {
            afterUnfavouriteCallback();
        }
    }

    render() {
        const { listingId, profileId, showFavourite } = this.props;

        return (
            <>
                {showFavourite ? (
                    <Favourite
                        onFavourite={this.onFavourite}
                        id={listingId || profileId}
                        {...this.props}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { listingId, profileId } = props;
    const membership = getAccountCurrentMembershipPlan(state);
    const membershipType = getMembershipType(membership);
    const membershipStatus = getMembershipStatus(membership);

    let isFavourited = false;
    let isSitterOrCombined = false;
    let showFavourite = false;
    // If there is a listing id then check if the sitter/combined member has favourited the listing
    if (
        listingId &&
        (membershipType === MembershipType.SITTER || membershipType === MembershipType.COMBINED)
    ) {
        isFavourited = isSitterOwnerListingFavourite(state, listingId);
        // they are an sitter type if they are sitter/combined and trying to favourite a listing
        isSitterOrCombined = true;
        // we show favourite if there is a listing id and they're a sitter/combined paid member
        showFavourite = membershipStatus === MembershipStatus.MEMBER;
    }
    // If there is a profile id then check if the owner/combined member has favourited the profile
    if (
        profileId &&
        (membershipType === MembershipType.OWNER || membershipType === MembershipType.COMBINED)
    ) {
        isFavourited = isOwnerSitterProfileFavourite(state, profileId);
        // we show favourite if there is a profile id and they're an owner/combined paid member
        showFavourite = membershipStatus === MembershipStatus.MEMBER;
    }

    return {
        ...props,
        isFavourited,
        isSitterOrCombined,
        showFavourite,
    };
};

const mapDispatchToProps = {
    createFavouriteAsSitter: actions.sitter.createFavourite,
    createFavouriteAsOwner: actions.owner.createFavourite,
    removeFavouriteAsSitter: actions.sitter.removeFavourite,
    removeFavouriteAsOwner: actions.owner.removeFavourite,
};

export { FavouriteComponent };
export default connect(mapStateToProps, mapDispatchToProps)(FavouriteComponent);
