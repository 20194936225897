import styled, { css, keyframes } from 'styled-components';
import { ButtonIcon } from 'components/buttons';
import { visuallyHide } from 'utils/utils';

const pulse = keyframes`
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.2)
    }
    100% {
        transform: scale(1)
    }
`;

const pulseAnimationRule = css`
    ${pulse} 0.7s ease-in-out;
`;

const favouritedButtonCss = css`
    & > span > svg {
        fill: ${({ theme }) => theme.palette.accent};
        stroke: ${({ theme }) => theme.palette.accent};
    }
`;

const unFavouritedButtonCss = css`
    & > span > svg {
        fill: transparent;
        stroke: ${({ theme, iconStyle }) =>
            iconStyle === 'notify' ? theme.palette.highlight : theme.palette.grey[600]};
        stroke-width: 2px;
    }
`;

const FavouriteButtonStyled = styled(ButtonIcon)`
    ${({ isFavourited }) => (isFavourited ? favouritedButtonCss : unFavouritedButtonCss)};
`;

/**
 * Checked css for favourite icon
 */
const favouritedIconCss = css`
    animation: ${pulseAnimationRule};

    & > span > svg {
        fill: ${({ theme }) => theme.palette.accent};
    }
`;

const unFavouritedIconCss = css`
    & > span > svg {
        stroke: white;
        stroke-width: 2px;
    }
`;

const IconWrapper = styled.span`
    ${({ theme }) => `
        width: ${theme.spacing[3]};
        height: ${theme.spacing[3]};
    `};

    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;

    & > span > svg {
        fill: transparent;
    }

    ${({ isFavourited }) => (isFavourited ? favouritedIconCss : unFavouritedIconCss)};
`;

const CheckboxInput = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
`;

const LabelStyled = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    :focus-within {
        outline: ${({ theme }) => theme.palette.focus} auto 5px;
        outline: -webkit-focus-ring-color auto 5px;
    }
`;

const IconLabelStyled = styled.span`
    ${visuallyHide};
`;

export { FavouriteButtonStyled, IconWrapper, CheckboxInput, LabelStyled, IconLabelStyled };
