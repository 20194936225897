import React from 'react';
import { Button } from 'components/buttons';
import { IconFavourite } from 'components/Icon';
import {
    LabelStyled,
    CheckboxInput,
    IconWrapper,
    FavouriteButtonStyled,
    IconLabelStyled,
} from './ListingFavourite.style';

const Favourite = ({
    isButton,
    onFavourite,
    disabled,
    isFavourited,
    id,
    t,
    className,
    unfavouritedTranslationKey = 'components_listingFavourite_button_unFavourited',
    variant,
}) => {
    if (!isButton) {
        return (
            <LabelStyled
                htmlFor={id}
                className={className}
                /* The decision to remove the onChange from input and create a onClick in label was due a weird behavior
                 * where after clicking on the heart of a listing into "Recommend for you" that also exists in general search, the screen scrolled to the top.
                 */
                onClick={(e) => {
                    onFavourite();
                    e.preventDefault();
                }}
            >
                <CheckboxInput disabled={disabled} id={id} type="checkbox" checked={isFavourited} />
                <IconWrapper disabled={disabled} isFavourited={isFavourited}>
                    <IconFavourite isFavourited={isFavourited} size="large" />
                </IconWrapper>
                <IconLabelStyled>{t('components_listingcard_favourite')}</IconLabelStyled>
            </LabelStyled>
        );
    }

    const buttonVariant =
        variant === 'notify' && !isFavourited ? Button.Variant.PRIMARY : Button.Variant.SECONDARY;

    return (
        <FavouriteButtonStyled
            isFavourited={isFavourited}
            icon={IconFavourite}
            variant={buttonVariant}
            iconProps={{ size: 'large' }}
            onClick={onFavourite}
            disabled={disabled}
            iconStyle={variant}
        >
            {isFavourited
                ? t('components_listingFavourite_button_favourited')
                : t(unfavouritedTranslationKey)}
        </FavouriteButtonStyled>
    );
};

export default Favourite;
